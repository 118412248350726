import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { hydrateRoot } from 'react-dom/client';
import { ClientProvider } from '@mantine/remix';
import * as Sentry from "@sentry/remix";
import { useEffect } from 'react';

Sentry.init({
  dsn: "https://51a9a792cf4f4df4b943211310a2391f:da4249226fe945be85844c0be720f20a@o1148927.ingest.sentry.io/4504612538613760",
  tracesSampleRate: 1,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.remixRouterInstrumentation(
        useEffect,
        useLocation,
        useMatches
      ),
    }),
  ],
});

hydrateRoot(document,
  <ClientProvider>
    <RemixBrowser />
  </ClientProvider>,
);